@use "../../abstracts/helpers" as *;

.main-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;

    .header-navigation {
        display: none;
    }
}

@include breakpoint(desktop, "screen") {

    .main-header {

        .header-navigation {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 16px;
    
            &::before {
                content: "/";
                font-weight: 400;
                font-size: 14px;
                line-height: 1.38;
                letter-spacing: -0.56px;
                color: var(--color-border);
            }
        }
    }
}