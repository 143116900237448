.most-popular-posts {
    padding-top: 20px;

    h5 {
        color: var(--color-text-secondary);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        letter-spacing: -0.12px;
        text-transform: uppercase;
        font-feature-settings: 'ss02' on;
        margin-bottom: 20px;
    }

    ol {
        padding-left: 26px;
        
        li {
            font-weight: 400;
            font-size: 16px;
            line-height: 1.4;
            letter-spacing: -0.64px;

            &:not(:last-of-type) {
                padding-bottom: 19px;
            }

            a {
                text-decoration: none;
                color: var(--color-text-primary);

                &:hover {
                    color: var(--color-brand-accent-green);
                }
            }
        }
    }
}