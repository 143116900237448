/**
 * Main SCSS file
 * 
 * @package RootPixel
 * @subpackage Daytona
 * @since Daytona 1.0.0
 */

// Basic files: breakpoints, CSS reset, variables, 
// base typography rules and rules for main elements
@use "abstracts/helpers" as *;
@use "base/reset" as *;
@use "base/variables" as *;
@use "base/typography" as *;
@use "base/base" as *;

// SCSS files related to page header
@use "components/header/layout" as *;
@use "components/header/branding" as *;
@use "components/header/blog" as *;
@use "components/header/navigation" as *;
@use "components/header/action-bar" as *;
@use "components/header/mobile-menu-button" as *;

// SCSS files for the different post lists, pagination
@use "components/blog-search-lists/featured-post-box" as *;
@use "components/blog-search-lists/guide-box" as *;
@use "components/blog-search-lists/most-popular-posts" as *;
@use "components/blog-search-lists/post-box" as *;
@use "components/blog-search-lists/update-box" as *;
@use "components/blog-search-lists/pagination" as *;
@use "components/blog-search-lists/view-all-link" as *;

// SCSS files related to page footer (incl. newsletter form)
@use "components/footer/newsletter" as *;
@use "components/footer/layout" as *;
@use "components/footer/branding" as *;
@use "components/footer/partner-with-us" as *;
@use "components/footer/copyright" as *;
@use "components/footer/deco-text" as *;
@use "components/footer/link-section" as *;
@use "components/footer/social-icons" as *;
@use "components/footer/bottom-section" as *;
@use "components/footer/legal-navigation" as *;
@use "components/footer/dark-mode" as *;

// SCSS files that contain the page-specific rules
@use "pages/blog-homepage" as *;