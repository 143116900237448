@use "../../abstracts/helpers" as *;

.footer-bottom-section {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}

@include breakpoint(desktop, "screen") {

    .footer-bottom-section {
        flex-wrap: nowrap;
    }
}