@use "../abstracts/helpers" as *;

.page-header--blog-home {
    padding-bottom: 40px;
    border-bottom: none;
    gap: 20px;

    .page-title-box {
        display: flex;
        flex-direction: row;
        gap: 10px;
        border-top: 1px solid var(--color-border);
        padding-top: 20px;
        flex-wrap: wrap;

        h1 {
            font-size: 24px;
            font-weight: 400;
            line-height: 1.2;
            letter-spacing: -0.96px;
            font-feature-settings: 'ss02' on;
        }

        .page-description {
            flex: 0 0 100%;
            font-weight: 500;
            font-family: var(--ff-sans-serif);
            font-size: 16px;
            line-height: 1.4;
            color: var(--color-text-secondary);
            letter-spacing: -0.24px;
            margin-top: 10px; // Margin top 10px + parent's gap 10px => 20px
        }
    }

    .most-popular-posts {
        display: none;
    }
}

.blog-homepage-logo {
    width: 32px;
    height: 32px;
}

.blog-homepage-logo__rectangle {
    transform-origin: center;
}

.guides-container {
    flex-direction: column;
    padding-bottom: 80px;
    border-bottom: 1px solid var(--color-border);

    h3 {
        font-size: 24px;
        font-weight: 400;
        line-height: 1.2;
        letter-spacing: -0.96px;
        color: var(--color-text-primary);
        font-feature-settings: 'ss02' on;
        margin-bottom: 20px;
    }

    .section-description {
        font-family: var(--ff-sans-serif);
        font-size: 12px;
        font-weight: 500;
        line-height: 1.4;
        letter-spacing: -0.24px;
        color: var(--color-text-secondary);
        margin-bottom: 40px;
        max-width: 520px;
    }

    .simple-row__right {

        .view-all-link {
            display: block;
            width: 100%;
            text-align: right;
            margin-top: 20px;
        }
    }
}

.updates-container {
    padding-top: 40px;
    margin-bottom: 80px;
    
    .simple-row__left {
        
        h5 {
            font-size: 12px;
            font-weight: 400;
            line-height: 100%;
            letter-spacing: -0.12px;
            text-transform: uppercase;
            font-feature-settings: 'ss02' on;
            color: var(--color-text-primary);

            .updates-title-deco {
                color: var(--color-brand-accent-green);
            }
        }
    }

    .section-description {
        font-family: var(--ff-sans-serif);
        font-weight: 400;
        font-size: 16px;
        line-height: 1.6;
        letter-spacing: -0.16px;
        color: var(--color-text-secondary);
        margin-bottom: 40px;
    }

    .view-all-link {
        display: block;
        color: var(--color-brand-accent-green);
        padding: 40px 0;
        border-top: 1px solid var(--color-border);
    }
}

@include breakpoint(tablet, "screen") {

    .page-header--blog-home {
        flex-direction: column;

        .simple-row__left,
        .simple-row__right {
            max-width: unset;
            width: 100%;
        }
    }

    .guides-container {

        h3 {
            font-size: 36px;
            line-height: 1.2;
            letter-spacing: -2.16px;
        }

        .simple-row__left {
            max-width: unset;
            width: 100%;

            .section-description {
                font-size: 16px;
                line-height: 1.6;
                letter-spacing: -0.16px;
            }
        } 
        
        .simple-row__right {
            
            .view-all-link {
                margin-top: 37px;
            }
        }
    }

    .updates-container {
        flex-direction: column;

        .simple-row__left,
        .simple-row__right {
            max-width: unset;
            width: 100%;
        }
    }
}

@include breakpoint(desktop, "screen") {

    .page-header--blog-home {
        gap: 48px;
        flex-direction: row;

        .simple-row__left {
            max-width: 276px;
        }

        .simple-row__right {
            max-width: 924px;
        }

        .page-title-box {
            flex-direction: column;
            padding-top: 40px;
            padding-bottom: 40px;
            border-bottom: 1px solid var(--color-border);
            gap: 20px;

            h1 {
                font-size: 36px;
                letter-spacing: -2.16px;
            }

            .page-description {
                margin-top: 0;
                font-weight: 400;
                font-size: 16px;
                line-height: 1.6;
                letter-spacing: -0.16px;
            }
        }

        .most-popular-posts {
            display: block;
        }
    }

    .guides-container {
        flex-direction: row;
        padding-top: 0;

        .simple-row__left {
            max-width: 276px;

            .section-description {
                margin-bottom: 40px;
            }
        }

        .simple-row__right {
            max-width: 924px;

            .view-all-link {
                margin-top: 48px;
             }
        }

        h3 {
            font-size: 36px;
            letter-spacing: -2.16px;
        }
    }

    .updates-container {
        flex-direction: row;

        .simple-row__left {
            max-width: 276px;
        }

        .simple-row__right {
            max-width: 924px;
        }

        .section-description {
            font-weight: 400;
            font-size: 16px;
            line-height: 1.6;
            letter-spacing: -0.16px;
        }
    }
}