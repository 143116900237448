@use "../../abstracts/helpers" as *;

.main-menu {
    display: none;
    padding: 0;
    list-style-type: none;
    gap: 16px;

    .menu-item {
        text-transform: uppercase;
    }
}

.menu-item {
    text-decoration: none;
    line-height: 1.2;
    letter-spacing: -0.64px;
    color: var(--color-text-primary);

    &:hover {
        color: var(--color-brand-accent-green);
    }
}

@include breakpoint(desktop, "screen") {

    .main-menu {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}