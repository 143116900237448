@use "../../abstracts/helpers" as *;

.footer-links-section {
    
    &__row {

        .footer-social-links {
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            flex-wrap: wrap;
            gap: 16px;

            h5 {
                padding-top: 40px;
                width: 100%;
                flex: 0 1 100%;
                margin-bottom: 0;
            }
            
            a {

                svg {

                    path {
                        fill: var(--color-text-secondary);
                    }
                }

                span {
                    display: none;
                }

                &:hover {
                    
                    svg {

                        path {
                            fill: var(--color-brand-accent-green);
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint(tablet, "screen") {

    .footer-links-section {

        &__row {
            
            .footer-social-links {
                display: block;
                width: 100%;
            
                h5 {
                    padding-top: 0;
                    margin-bottom: 16px;
                }

                a {
                    display: flex;
                    flex-direction: row;
                    justify-content: left;
                    align-items: center;
                    gap: 8px;

                    svg {
    
                        path {
                            fill: var(--color-text-primary);
                        }
                    }
    
                    span {
                        display: inline-block;
                    }
                }
            }
        }
    }
}