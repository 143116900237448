@use "../../abstracts/helpers" as *;

.pagination-container {
    padding: 20px 0;
    border-top: 1px solid var(--color-border);

    .pagination-numbers-container {
        display: none;
    }
}

.pagination-container--post-list {
    margin-bottom: 100px;
}

@include breakpoint(desktop, "screen") {

    .pagination-container {
        padding: 40px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .pagination-numbers-container,
        .pagination-numbers-container .pagination-numbers-navigation {
            display: flex;
            justify-content: left;
            align-items: center;
            gap: 20px;
        }

        select {
            border: 1px solid var(--color-border);
            text-align: center;
            font-family: var(--ff-sans-serif);
            font-size: 12px;
            font-weight: 500;
            line-height: 23px; /* 191.667% */
            letter-spacing: -0.12px;
            color: var(--color-text-secondary);
            height: 24px;
            padding: 0 28px 0 10px;
            -webkit-appearance: none;
            -moz-appearance: none;
            background: var(--color-bg-primary);
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="4" viewBox="0 0 8 4" fill="none"><path d="M8 0L4 4L0 0H8Z" fill="white"/></svg>');
            background-position-x: calc(100% - 10px);
            background-position-y: 50%;
            background-repeat: no-repeat;
        }

        .pagination-numbers-navigation {
            
            .pagination-number {
                display: inline-block;
                width: 24px;
                text-align: center;
                font-size: 16px;
                text-decoration: none;
                line-height: 24px;
                color: var(--color-border);

                &--active {
                    color: var(--color-text-secondary);
                    cursor: not-allowed;
                    pointer-events: none;
                }

                &--text {
                    svg {
                        path {
                            fill: var(--color-text-secondary);
                        }
                    }

                    &:hover {
                        svg {
                            path {
                                fill: var(--color-brand-accent-green);
                            }
                        }
                    }
                }

                &:hover {
                    color: var(--color-brand-accent-green);
                }
            }
        }
    }

    body:not(.dark-mode) {

        .pagination-container {

            select {
                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="4" viewBox="0 0 8 4" fill="none"><path d="M8 0L4 4L0 0H8Z" fill="%23828282"/></svg>');
            }
        }
    }
}