@use "../../abstracts/helpers" as *;

.update-list-container {
    width: 100%;

    .update-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        gap: 20px;
        padding: 20px 0 0 0;
        border-top: 1px solid var(--color-border);

        h3 {
            width: 100%;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.4;
            letter-spacing: -0.64px;

            a {
                text-decoration: none;
                color: var(--color-text-primary);

                &:hover {
                    color: var(--color-brand-accent-green);
                }
            }
        }

        .publishing-time {
            display: block;
            width: 100px;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.2;
            letter-spacing: -0.56px;
            color: var(--color-text-secondary);
        }

        h3:hover + .publishing-time {
            color: var(--color-brand-accent-green);
        }
    }
}

@include breakpoint(tablet, "screen") {

    .update-list-container {
        
        .update-box {
    
            h3 {
                font-size: 20px;
                letter-spacing: -0.8px;
            }
        }
    }
}

@include breakpoint(desktop, "screen") {

    .update-list-container {

        .update-box {
            padding: 40px 0;
            flex-direction: row;

            h3 {
                font-size: 24px;
                line-height: 1.3;
                letter-spacing: -0.96px;
            }
        }
    }
}