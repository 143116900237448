.header-navigation--blog-item {

    .menu-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;

        svg {
            width: 16px;
            height: 16px;

            path {
                fill: var(--color-icon-rect-fill);
                stroke: var(--color-icon-rect-stroke);
            }

            circle {
                fill: var(--color-icon-circle-fill);
            }
        }
    }
}