@use "../../abstracts/helpers" as *;

.simple-row--footer {
    padding-top: 40px;
    padding-bottom: 40px;
    align-items: stretch;
    gap: 0;
    border-top: 1px solid var(--color-border);
    
    .simple-row__left,
    .simple-row__right {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .simple-row__left {
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 32px;
    }
}

@include breakpoint(tablet, "screen") {

    .simple-row--footer {
        padding-top: 40px;
        padding-bottom: 40px;
        gap: 0;
        flex-direction: column;

        .simple-row__left {
            max-width: 100%;
        }

        .simple-row__right {
            max-width: 100%;
        }
    }
}

@include breakpoint(desktop, "screen") {
    
    .simple-row--footer {
        padding-top: 0;
        padding-bottom: 60px;
        gap: 48px;
        flex-direction: row;
        border-top: none;
        
        .simple-row__left {
            padding-top: 16px;
            flex-direction: column;
            align-items: flex-start;
            max-width: 276px;
            padding-bottom: 0;
        }
    
        .simple-row__right {
            display: flex;
            max-width: 924px;
        }
    }
}
