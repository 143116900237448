@use "../../abstracts/helpers" as *;

.action-bar {
    flex: 1;
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 0;

    .header-search-button {
        border: 1px solid var(--color-border);
        border-right: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;

        svg {
            path {
                fill: var(--color-text-primary);
            }
        }
    }

    .header-sign-in-button,
    .header-github-badge {
        display: none;
    }
}

@include breakpoint(desktop, "screen") {
    
    .action-bar {
        gap: 8px;

        .header-search-button {
            border: none;
            width: auto;
        }

        .header-sign-in-button {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            padding: 0 16px;
            text-decoration: none;
            line-height: 30px;
            letter-spacing: -0.56px;
            color: var(--color-brand-primary);
            background-color: var(--color-brand-primary-20);
            border-radius: 3px;
        }
    
        .header-github-badge {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            height: 30px;
            padding: 0 16px;
            text-decoration: none;
            line-height: 30px;
            letter-spacing: -0.14px;
            color: var(--color-text-primary);
            background-color: var(--color-bg-secondary);
            border-radius: 3px;
    
            svg {
                path {
                    fill: var(--color-text-secondary);
                }
            }
        }
    }
}