@use "../../abstracts/helpers" as *;

.footer-copyright {
    color: var(--color-text-primary);
    font-weight: 500;
    font-size: 12px;
    line-height: 1;
    letter-spacing: -0.12px;
    text-transform: uppercase;
    font-feature-settings: 'ss02' on;
    white-space: nowrap;
}
