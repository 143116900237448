/*
 * Define breakpoints for viewports
 *
 */

 // Edit breakpoints in helpers.scss
 $breakpoints: (
    tablet: 48rem,  // ~800px
    desktop: 81rem   // ~1296px
  ) !default;
  
@mixin breakpoint($breakpoint, $logic: false) {
    @if( $logic ) {
        @media #{$logic} and (min-width: map-get($map: $breakpoints, $key: $breakpoint)) { @content; }
    } @else {
        @media (min-width: map-get($map: $breakpoints, $key: $breakpoint)) { @content; }
    }
}

/* Retina media query */
@mixin retina {
    @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
        @content;
    }
}