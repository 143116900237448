@use "../../abstracts/helpers" as *;

.simple-row--newsletter {
    padding: 80px 0;
    border-top: 1px solid var(--color-border);

    .simple-row__left {
        
        h4 {
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            gap: 12px;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 400;
            line-height: 1;
            letter-spacing: -0.12px;
            color: var(--color-text-secondary);
        }
    }

    .simple-row__right {

        p {
            font-family: var(--ff-sans-serif);
            font-size: 20px;
            font-weight: 500;
            line-height: 1.5;
            letter-spacing: -0.2px;
            color: var(--color-text-secondary);

            .deco-text-in-paragraph {
                color: var(--color-text-primary);
            }
        }

        .newsletter-form {
            margin-top: 40px;
            font-family: var(--ff-sans-serif);
            font-weight: 500;
            line-height: 1.2;
            font-size: 14px;
            letter-spacing: -0.14px;
            
            .field-container {
                display: flex;
                margin-top: 10px;
                gap: 0;
                width: 100%;
            }

            input[type="email"] {
                flex: 1;
                border: 1px solid var(--color-border);
                border-right: none;
                outline: none;
                padding: 20px 0 20px 20px;
                background: var(--color-bg-primary);
                color: var(--color-text-primary);
                transition: all .3 ease;
                font-size: 16px;
            }

            button[type="submit"] {
                background: transparent;
                border: 1px solid var(--color-border);
                border-left: none;
                outline: none;
                cursor: pointer;
                padding: 0 20px;
                transition: all .3 ease;

                svg {
                    path {
                        fill: var(--color-text-primary);
                        opacity: .3;
                        transition: all .3 ease;
                    }
                }
            }

            input[type="email"]:focus {
                border-color: var(--color-text-primary);
                transition: all .3 ease;
            }

            input[type="email"]:focus + button[type="submit"] {
                border-color: var(--color-text-primary);
                transition: all .3 ease;

                svg {
                    path {
                        opacity: 1;
                        transition: all .3 ease;
                    }
                }
            }
        }
    }
}

@include breakpoint(tablet, "screen") {

    .simple-row--newsletter {
        flex-direction: column;

        .simple-row__right,
        .simple-row__left {
            max-width: unset;
        }
    }
}

@include breakpoint(desktop, "screen") {

    .simple-row--newsletter {
        flex-direction: row;

        .simple-row__right {
            max-width: 700px;
            gap: 40px;

            .newsletter-form {
                margin-top: 40px;
            }
        }
    }
}