/*
 * This file forwards the variables, mixins and functions 
 */

@forward 'breakpoints' with (
    $breakpoints: (
        'tablet': 50rem,  // ~800px
        'desktop': 81rem  // ~1296px
    )
);

// @forward 'mixins';
// @forward 'functions';