@use "../../abstracts/helpers" as *;

.post-list {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 40px;
}

.post-box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    text-decoration: none;
    color: var(--color-text-primary);

    &__image {
        position: relative;
        border: 1px solid var(--color-border);
        // For image cover
        display: flex;
        align-content: stretch;
        align-items: stretch;
        
        img {
            object-fit: cover;
            flex: 1;
        }

        &::after {
            content: "";
            position: absolute;
            width: calc(100% - 20px);
            height: 100%;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: transparent;
            z-index: -1;
            transition: background-color 0.25s cubic-bezier(0.25, 0.1, 0.25, 1), top 0.25s cubic-bezier(0.25, 0.1, 0.25, 1), left 0.25s cubic-bezier(0.25, 0.1, 0.25, 1);
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 10px;

        h3 {
            font-size: 16px;
            font-weight: 400;
            line-height: 1.2;
            letter-spacing: -0.64px;
        }

        .post-meta {

            .publishing-time,
            .post-author {
                color: var(--color-text-secondary);
                font-size: 12px;
                line-height: 1.2;
                letter-spacing: -0.48px;
            }

            & .publishing-time + .post-author::before {
                content: '::';
                padding-left: 5px;
                padding-right: 5px;
                letter-spacing: 0;
                pointer-events: none;
                cursor: default;
            }

            .post-author {
                text-transform: uppercase;
            }
        }
    }

    &:hover {

        .post-box__image {
            border: 1px solid var(--color-brand-accent-green);

            &::after {
                background-color: var(--color-brand-accent-green);
                top: -10px;
            }
        }

        .read-more-link {
            color: var(--color-brand-accent-green);
        }
    }

    .read-more-link {
        display: none;
    }
}

@include breakpoint(tablet, "screen") {

    .post-list {
        gap: 0;
        margin-bottom: 0;
    }

    .post-box {
        flex-direction: row;
        align-items: stretch;
        justify-content: flex-start;
        gap: 48px;
        padding: 40px 0;
        border-top: 1px solid var(--color-border);
        
        &__image {
            min-width: 381px;
            width: 381px;
            height: 192px;
            // For image cover
            display: flex;
            align-content: stretch;
            align-items: stretch;
            
            img {
                object-fit: cover;
                flex: 1;
            }

            &::after {
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                transform: translateX(0);
            }
        }

        &__content {
            flex: 1;
            justify-content: stretch;
            gap: 16px;

            h3 {
                font-size: 20px;
                letter-spacing: -0.8px;
            }

            .post-meta {

                .publishing-time,
                .post-author {
                    font-size: 14px;
                    letter-spacing: -0.56px;
                }
            }
        }

        .read-more-link {
            display: block;
            width: 87px;
            text-decoration: none;
            color: var(--color-text-secondary);
            font-size: 14px;
            font-weight: 400;
            line-height: 1.2;
            letter-spacing: -0.56px;
            text-transform: uppercase;
            text-align: right;

            &::after {
                content: " ↗";
                font-size: 14px;
                font-weight: 400;
                line-height: 1.2;
            }

            &.read-more-link--tablet {
                flex: 1 1 100%;
                display: flex;
                align-items: flex-end;
                gap: 5px;
            }

            &.read-more-link--desktop {
                display: none;
            }
        }

        &:hover {

            .post-box__image {
                
                &::after {
                    top: 10px;
                    left: 10px;
                }
        
                .read-more-link {
                    color: var(--color-brand-accent-green);
                }
            }

            .read-more-link {
                color: var(--color-brand-accent-green);


            }
        }
    }
}

@include breakpoint(desktop, "screen") {

    .post-box {

        &__content {
            justify-content: space-between;
            
            h3 {
                font-size: 32px;
                letter-spacing: -1.28px;
            }
        }

        .read-more-link {

            &.read-more-link--tablet {
                display: none;
            }

            &.read-more-link--desktop {
                display: block;
            }
        }
    }
}