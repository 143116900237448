@use "../abstracts/helpers" as *;

body {
    background-color: var(--color-bg-primary);
    color: var(--color-text-primary);
    font-size: 14px;
}

// Change the background- and foreground color, when visitor select the text
::selection {
    background-color: var(--color-text-primary);
    color: var(--color-bg-primary);
}

// Set the dimensions of the 3 main area of page
.main-header,
.content-area,
.main-footer {
    width: 100%;
    max-width: 1248px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto;
}

.simple-row {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 48px;

    &__left {
        max-width: 100%;
    }

    &__right {
        max-width: 100%;
    }
}

@include breakpoint(tablet, "screen") {

    .main-header,
    .content-area,
    .main-footer {
        padding-left: 40px;
        padding-right: 40px;
    }
    
    .simple-row {
        flex-direction: row;

        &__left {
            min-width: 276px;
            max-width: 276px;
        }

        &__right {
            max-width: 924px;
        }
    }
}

@include breakpoint(desktop, "screen") {
    .main-header,
    .content-area,
    .main-footer {
        padding-left: 0;
        padding-right: 0;
    }
}