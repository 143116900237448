@use "../../abstracts/helpers" as *;

.mobile-menu-button-container {

    .mobile-menu-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border: 1px solid var(--color-border);
        text-decoration: none;

        &__icon {
            path {
                fill: var(--color-text-primary);
            }
        }
    }
}

@include breakpoint(desktop, "screen") {
    
    .mobile-menu-button-container {
        display: none;
    }
}