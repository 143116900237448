@use "../../abstracts/helpers" as *;

.footer-deco-text {
    
    &__container {
        display: flex;

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid var(--color-border);
            color: var(--color-text-secondary);
            font-size: 12px;
            font-feature-settings: 'ss02' on;
            line-height: 1;
            letter-spacing: -0.12px;
            text-transform: uppercase;

            &:first-of-type {
                width: 36px;
                height: 36px;
            }

            &:nth-of-type(2) {
                width: 75px;
                height: 36px;
                border-left: none;
            }
        }
    }
}

@include breakpoint(desktop, "screen") {

    .footer-deco-text {
        flex: 1;
        display: flex;
        align-items: flex-end;
    }
}