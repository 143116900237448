.view-all-link {
    font-size: 16px;
    line-height: normal;
    text-decoration: none;
    color: var(--color-text-primary);
    letter-spacing: -0.16px;

    &:hover {
        color: var(--color-brand-accent-green);
    }
}