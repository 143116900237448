@use "../../abstracts/helpers" as *;

.footer-links-section {
    width: 100%;

    &__row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-top: 18px;
        padding-bottom: 40px;
        border-bottom: 1px solid var(--color-border);
        margin-bottom: 40px;

        nav {
            flex-basis: 50%;    

            h5 {
                color: var(--color-text-secondary);
                font-size: 12px;
                font-weight: 400;
                line-height: 1;
                letter-spacing: -0.12px;
                text-transform: uppercase;
                font-feature-settings: 'ss02' on;
                margin-bottom: 16px;
            }

            a {
                display: block;
                color: var(--color-text-primary);
                font-family: var(--ff-sans-serif);
                font-size: 16px;
                line-height: 1.6;
                letter-spacing: -0.16px;
                text-decoration: none;

                &:hover {
                    color: var(--color-brand-accent-green);
                }

                &:not(:last-of-type) {
                    margin-bottom: 4px;
                }
            }
        }

        // Container of popular topics
        &:last-of-type {
            display: none;
            padding-top: 18px;
            padding-bottom: 0;
            border-bottom: none;
            margin-bottom: 0;
        }
    }
}

@include breakpoint(tablet, "screen") {

    .footer-links-section {

        &__row {
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 48px;
            padding-top: 0;
            padding-bottom: 40px;
            margin-bottom: 20px;

            &:last-of-type {
                border-bottom: none;
                padding-top: 0;
                padding-bottom: 0;
                margin-bottom: 0;
            }

            nav {
                flex-basis: unset;
                width: 100%;
            }

            nav:not(.footer-social-links),
            // Popular topics container is flexbox
            nav:not(.popular-topics-container) {
                display: block;
            }

            nav.popular-topics-container {
                display: none;
            }
        }

        &__row:not(.footer-social-link-container) {
            display: flex;
        }
    }
}

@include breakpoint(desktop, "screen") {

    .footer-links-section {

        &__row {
            padding-bottom: 60px;

            &:last-of-type {
                border-bottom: 1px solid var(--color-border);
                padding-bottom: 40px;
                margin-bottom: 20px;
            }

            nav.popular-topics-container {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                gap: 40px;

                h5 {
                    margin-bottom: 0;
                    flex: 0 0 100%;
                }

                > div {
                    flex-basis: calc(50% - 20px);
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                }
            }
        }

        &:last-of-type {
            padding-top: 18px;
            padding-bottom: 40px;
            border-bottom: 1px solid var(--color-border);
            margin-bottom: 40px;
        }
    }
}