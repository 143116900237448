@use "../../abstracts/helpers" as *;

.footer-legal-navigation {
    order: 3;
    display: flex;
    justify-content: left;
    gap: 16px;
    width: 100%;

    a {
        font-weight: 500;
        font-size: 12px;
        font-family: var(--ff-sans-serif);
        line-height: 1.4;
        text-decoration: none;
        letter-spacing: -0.24px;
        color: var(--color-text-secondary);
    }
}

@include breakpoint(desktop, "screen") {

    .footer-legal-navigation {
        order: 2;

        &::before {
            content: "/";
            font-weight: 500;
            font-size: 12px;
            font-family: var(--ff-sans-serif);
            line-height: 1.4;
            letter-spacing: -0.24px;
            color: var(--color-border);
        }
    }
}