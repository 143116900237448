@use "../../abstracts/helpers" as *;

.branding {

    &__container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;
        padding: 20px 0;
    }

    &__icon {

        rect {
            stroke: var(--color-text-primary);
        }

        path {
            fill: var(--color-text-primary);
        }
    }

    &__name {
        margin-top: 4px;

        path {
            fill: var(--color-text-primary);
        }
    }
}