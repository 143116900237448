@use "../../abstracts/helpers" as *;

.partner-with-us {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-top: 1px solid var(--color-border);
    border-bottom: 1px solid var(--color-border);
    padding: 20px 0;

    &__text {
        display: flex;
        justify-content: left;
        align-items: center;
        gap: 10px;

        &__label {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: -0.48px;
            border-radius: 3px;
            background-color: var(--color-bg-primary);

            &::after {
                content: "";
                position: absolute;
                width: calc(100% + 2px);
                height: calc(100% + 2px);
                top: -1px;
                left: -1px;
                background: var(--gradient-primary);
                border-radius: 3px;
                z-index: -1;
            }

            .partner-with-us__text__helper {
                margin: 1px 4px 0 4px;
                /* Fallback: Set a background color. */
                background-color: var(--color-bg-primary);
                /* Create the gradient. */
                background-image: var(--gradient-primary);
                /* Set the background size and repeat properties. */
                background-size: 100%;
                background-repeat: repeat;
                /* Use the text as a mask for the background. */
                /* This will show the gradient as a text color rather than element bg. */
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent; 
                -moz-background-clip: text;
                -moz-text-fill-color: transparent;
            }
        }

        &__plain {
            font-size: 16px;
            font-weight: 400;
            line-height: 1.4;
            letter-spacing: -0.64px;
        }
    }

    &__link {
        font-size: 12px;
        line-height: 1;
        letter-spacing: -0.12px;
        text-transform: uppercase;
        text-decoration: none;
        color: var(--color-text-primary);
        font-feature-settings: 'ss02' on;

        &:hover {
            color: var(--color-brand-accent-green);
        }
    }
}

@include breakpoint(tablet, "screen") {

    .partner-with-us {
        margin-bottom: 40px;
    }
}

@include breakpoint(desktop, "screen") {

    .partner-with-us {
        margin-bottom: 60px;
    }
}