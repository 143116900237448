@use "../../abstracts/helpers" as *;

.featured-post {

    &__image-container {
        border: 1px solid var(--color-border);
        margin-bottom: 20px;

        .featured-image--desktop {
            display: none;
        }
    }

    .post-meta {
        .post-info {
            .publishing-time,
            .post-author {
                text-decoration: none;
                color: var(--color-text-secondary);
                font-size: 14px;
                line-height: 1.2;
                letter-spacing: -0.56px;
            }

            & .publishing-time + .post-author::before {
                content: '::';
                padding-left: 5px;
                padding-right: 5px;
                letter-spacing: 0;
                pointer-events: none;
                cursor: default;
            }

            .post-author {
                text-transform: uppercase;

                &:hover {
                    color: var(--color-brand-accent-green);
                }
            }
        }

        .read-more-link {
            display: none;
        }
    }

    h2 {
        font-size: 24px;
        font-weight: 400;
        line-height: 1.2;
        letter-spacing: -0.96px;
        margin: 16px 0;

        a {
            color: var(--color-text-primary);
            text-decoration: none;

            &:hover {
                color: var(--color-brand-accent-green);
            }
        }
    }

    .post-excerpt {
        font-family: var(--ff-sans-serif);
        color: var(--color-text-secondary);
        font-size: 14px;
        line-height: 1.4;
        letter-spacing: -0.14px;
    }
}

@include breakpoint(tablet, "screen") {

    .featured-post {

        h2 {
            font-size: 36px;
            line-height: 1.2;
            letter-spacing: -2.16px;
        }

        .post-excerpt {
            font-size: 20px;
            font-weight: 500;
            line-height: 1.5;
            letter-spacing: -0.2px;
        }
    }
}

@include breakpoint(desktop, "screen") {

    .featured-post {
        display: flex;
        flex-direction: column;
        gap: 16px;

        &__image-container {
            margin-bottom: 0;

            .featured-image--mobile {
                display: none;
            }

            .featured-image--desktop {
                display: block;

            }
        }

        .post-meta {
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            .read-more-link {
                display: block;
                text-decoration: none;
                color: var(--color-text-secondary);
                font-size: 14px;
                font-weight: 400;
                line-height: 1.2;
                letter-spacing: -0.56px;
                text-transform: uppercase;

                &::after {
                    content: " ↗";
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 1.2;
                }

                &:hover {
                    color: var(--color-brand-accent-green);
                }
            }
        }

        h2 {
            font-size: 48px;
            font-weight: 400;
            letter-spacing: -1.92px;
            margin: 0 0;
        }

        .post-excerpt {
            font-size: 16px;
            line-height: 1.6;
            letter-spacing: -0.16px;
            max-width: 600px;
        }
    }
}