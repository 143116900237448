@use "../../abstracts/helpers" as *;

.footer-bottom-section__dark-mode-container {
    order: 2;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 4px;

    .dark-mode-button {
        width: 28px;
        height: 28px;
        border: none;
        outline: none;
        padding: 4px;
        border-radius: 3px;
        background-color: var(--color-bg-primary);
        cursor: pointer;

        svg {
            width: 19px;
            height: 19px;

            path {
                fill: var(--color-text-secondary);
            }
        }

        &.dark-mode--active {
            background-color: var(--color-bg-secondary);
            pointer-events: none;
        
            svg {
        
                path {
                    fill: var(--color-text-primary);
                }
            }
        }
    }
}

@include breakpoint(desktop, "screen") {

    .footer-bottom-section__dark-mode-container {
        order: 3;
    }
}