/**
 * Define all variables
 */
:root {

    // Font families
    --ff-mono: 'Berkeley Mono', monospace;
    --ff-sans-serif: 'Inter', sans-serif;

    // Gradient
    --gradient-primary: linear-gradient(198deg, #007FFF 12.46%, #8558DE 87.61%);
    --gradient-green-green-15: linear-gradient(180deg, #00E49A 0%, rgba(0, 228, 154, 0.15) 100%);

    // Brand colors - these colors won't be changed in dark mode
    --color-brand-primary: #007fff;
    --color-brand-primary-20: rgba(0, 127, 255, .2);
    --color-brand-secondary: #8558de;
    
    // Brand colors - these colors will be changed in dark mode
    --color-brand-accent-blue: #0789b6;
    --color-brand-accent-green: #08ae78;
    --color-brand-accent-orange: #bc530a;
    --color-brand-accent-yellow: #c59f14;

    // Element colors - these colors will be changed in dark mode
    --color-text-primary: #121212;
    --color-text-secondary: #828282;
    --color-bg-primary: #ffffff;
    --color-bg-secondary: #f7f7f7;
    --color-border: #dedede;

    // Blog icon colors on header's navigation bar
    --color-icon-circle-fill: var(--color-bg-primary);
    --color-icon-rect-fill: var(--color-brand-accent-green);
    --color-icon-rect-stroke: var(--color-brand-accent-green);
    
    // Decoration text in updates section's title
    --color-updates-title-deco: var(--color-brand-accent-green);
}

// Change the colors in dark-mode
.dark-mode {

    // Brand colors
    --color-brand-accent-blue: #00b9fb;
    --color-brand-accent-green: #00e49a;
    --color-brand-accent-orange: #fb6500;
    --color-brand-accent-yellow: #fbc300;

    // Element colors
    --color-text-primary: #ffffff;
    --color-text-secondary: #a8a8a8;
    --color-bg-primary: #0a0a0a;
    --color-bg-secondary: #161616;
    --color-border: #414141;

    // Blog icon colors on header's navigation bar
    --color-icon-circle-fill: var(--color-brand-accent-green);
    --color-icon-rect-fill: none;
    --color-icon-rect-stroke: var(--color-brand-accent-green);

    // Decoration text in updates section's title
    --color-updates-title-deco: var(--color-text-secondary);
}