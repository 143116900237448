/**
 * Default font stack:
 *     - Berkeley Mono: Daytona's default font
 *     - ui-monospace: Future system monospace font. Already supported in Safari 13.1. This is also the only way to access macOS’ new ‘SF Mono’ typeface.
 *     - Menlo, Monaco: Default in older versions of macOS / OSX, but also used in browsers that don’t support ui-monospace on macOS.
 *     - Cascadia Mono: It is a new monospace font Microsoft is working on that ships with Windows Terminal
 *     - Segoe UI Mono: It has been shipped with some Microsoft software (e.g. Office), so it’s worth including here
 *     - Roboto Mono: Roboto is the default font for newer versions of Android
 *     - Oxygen Mono: Oxygen font family is the default font family on KDE workspace's Oxygen theme (openSUSE, Fedora, Kubuntu, ...)
 *     - Ubuntu Monospace: Default monospace font on Ubuntu
 *     - Source Code Pro: Default in GNOME, but from a different family as the default variable-width typeface ‘Cantarell’
 *     - Fira Mono: Used (and developed) by Mozilla, it is commonly used in various software applications, websites, and by individual users
 *     - Droid Sans Mono: It is an open-source font family developed by Google and was available for use in various applications and projects
 *     - Courier New: It is available on most Windows and macOS systems by default
 *     - monospace: Generic font - it tells the browser to use a generic monospace font. Browsers will typically use the default monospace font available on the user's system.
 */
body {
    font-family: 'Berkeley Mono',
            ui-monospace, 
            Menlo, Monaco, 
            "Cascadia Mono", "Segoe UI Mono", 
            "Roboto Mono", 
            "Oxygen Mono", 
            "Ubuntu Monospace", 
            "Source Code Pro",
            "Fira Mono", 
            "Droid Sans Mono", 
            "Courier New", monospace;
}
