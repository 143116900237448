@use "../../abstracts/helpers" as *;

.guide-box-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    align-items: center;

    .guide-box {
        position: relative;
        width: 350px;
        height: 400px;
        border: 1px solid var(--color-border);
        background-color: var(--color-bg-primary);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h3 {
            padding: 20px;
            font-size: 24px;
            font-weight: 400;
            line-height: 1.1;
            letter-spacing: -0.96px;

            a {
                text-decoration: none;
                color: var(--color-text-primary);
            }
        }

        &__image {
            width: 100%;
            height: 283px;
            overflow: hidden;
            opacity: 0.5;
            
            img {
                height: 100%;
                width: 100%;
                filter: invert(100%);
            }
        }

        .read-more-button {
            display: none;
        }

        &::after {
            content: '';
            position: absolute;
            width: calc(100% - 20px);
            height: 100%;
            display: block;
            z-index: -1;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: var(--color-brand-accent-green);
            transition: top 0.25s cubic-bezier(0.25, 0.1, 0.25, 1), left 0.25s cubic-bezier(0.25, 0.1, 0.25, 1);
        }

        &:hover {
            border: 1px solid var(--color-brand-accent-green);
            
            .read-more-button {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                right: 19px;
                bottom: 27px;
                width: 34px;
                height: 34px;
                background-color: var(--color-bg-primary);

                svg {

                    path {
                        fill: var(--color-text-primary);
                    }
                }
            }

            .guide-box__image {
                opacity: 1;
            }
        }

        &:hover::after {
            top: -10px;
        }
    }
}

@include breakpoint(tablet, "screen") {

    .guide-box-container {
        gap: 37px;

        .guide-box {
            width: 342px;
            height: 359px;

            h3 {
                padding: 16px;
            }

            &__image {
                width: 100%;
                height: 229px;
            }

            &::after {
                width: 100%;
                left: 0;
                transform: translateX(0);
            }

            &:hover::after {
                top: 25px;
                left: 20px;
            }
        }
    }
}

@include breakpoint(desktop, "screen") {

    .guide-box-container {
        gap: 48px;
        
        .guide-box {
            width: 438px;
            height: 460px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            h3 {
                font-size: 36px;
                line-height: 1.2;
                letter-spacing: -2.16px;
                padding: 20px 20px 17px 20px;
                margin-bottom: 0;
            }

            &__image {
                width: 100%;
                height: 293px;
            }
        }
    }
}

// In dark mode disable color inversion
.dark-mode {
    .guide-box-container {
        .guide-box {
            &__image {
                img {
                    filter: invert(0%);
                }
            }
        }
    }
}